<script setup lang="ts">
import { cva, type VariantProps } from 'class-variance-authority';

const imageClasses = cva(['bg-center'], {
  variants: {
    fill: {
      auto: ['bg-auto'],
      contain: ['bg-contain'],
      cover: ['bg-cover'],
    },
  },
  defaultVariants: {
    fill: 'cover',
  },
});

type ImageClassesProps = VariantProps<typeof imageClasses>;

const props = withDefaults(
  defineProps<{
    fill?: ImageClassesProps['fill'];
    overlay?: 'none' | 'darken' | 'gradient';
    src: string;
  }>(),
  { fill: 'cover', overlay: 'none' }
);

const imageStyle = computed(() => {
  let overlayStyle = '';

  switch (props.overlay) {
    case 'darken':
      overlayStyle = 'linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.1)), ';
      break;
    case 'gradient':
      overlayStyle = 'linear-gradient(to top, rgba(0,0,0,0.5), rgba(0,0,0,0.3)), ';
      break;
  }

  return `background-image:${overlayStyle}url('${props.src}');`;
});
</script>

<template>
  <div :class="imageClasses({ fill })" :style="imageStyle">
    <slot />
  </div>
</template>
