<script setup lang="ts">
import { RectangleStackIcon, DocumentIcon, AcademicCapIcon, FilmIcon } from '@heroicons/vue/24/outline';
import { PlayCircleIcon } from '@heroicons/vue/24/solid';
import type { SanityImageObject } from '@sanity/image-url/lib/types/types';
import type { ResourceType } from '~/data/resourceTypes';

const props = withDefaults(
  defineProps<{
    description?: string;
    durationInMinutes?: number;
    isCollectionPage?: boolean;
    isFeaturedOnResourcePage?: boolean;
    isGalleryView?: boolean;
    resourceTitle: string;
    resourceType: ResourceType;
    showDuration?: boolean;
    image?: SanityImageObject | null;
  }>(),
  {
    description: '',
    durationInMinutes: 0,
    isCollectionPage: false,
    isFeaturedOnResourcePage: false,
    isGalleryView: false,
    showDuration: false,
    image: null,
  }
);

const { locale } = useI18n();
const { $urlFor } = useNuxtApp();
const formatDuration = useFormatDuration();

const localeCode = computed(() => {
  // TODO: Improve flexibility of locale code typing
  return locale.value as 'es' | 'en' | 'fr';
});

const formattedDuration = computed(() => formatDuration(props.durationInMinutes));

const icon = computed(() => {
  switch (props.resourceType.slug) {
    case 'collection':
      return RectangleStackIcon;
    case 'document':
      return DocumentIcon;
    case 'module':
      return AcademicCapIcon;
    case 'video':
      return FilmIcon;
    default:
      // TODO: Track error here: This code should never be reached
      return DocumentIcon;
  }
});

const isCollection = computed(() => {
  return props.resourceType.slug === 'collection';
});

const imageFrame = computed(() => {
  if (isCollection.value) {
    return 'none';
  }

  return props.isFeaturedOnResourcePage ? 'lg' : 'md';
});

const imageUrl = computed(() => {
  return props.image ? $urlFor(props.image).maxHeight(512).url() : '';
});
</script>

<template>
  <ContentPreviewImage
    class="flex-shrink-0"
    :class="{
      'h-56 md:h-64 lg:h-72 lg:w-72': isGalleryView,
      'hidden h-28 w-28 xs:flex sm:h-48 sm:w-48 lg:h-60 lg:w-60':
        !isGalleryView && !isFeaturedOnResourcePage && !isCollectionPage,
      'h-68 w-full p-8 md:h-96 lg:h-128': isFeaturedOnResourcePage,
      'mb-6 h-64 w-full md:mb-8 lg:h-76': isCollectionPage,
    }"
    :alt="description || null"
    :category-label="resourceType.name[localeCode]"
    :duration="durationInMinutes && showDuration ? formattedDuration : null"
    :fallback-icon="icon"
    :hide-label="!isGalleryView"
    :image-frame="imageFrame"
    :label-style="isCollection ? 'outline' : 'solid'"
    :overlay-icon="imageUrl && ['video'].includes(resourceType.slug) ? PlayCircleIcon : null"
    :src="imageUrl || null"
  />
</template>
