<script setup lang="ts">
import { cva, type VariantProps } from 'class-variance-authority';

const previewImageClasses = cva(['border-white'], {
  variants: {
    frame: {
      none: [],
      md: ['border', 'border-10'],
      lg: ['border', 'border-20'],
    },
    shadow: {
      none: [],
      lg: ['shadow-lg'],
    },
  },
  defaultVariants: {
    frame: 'none',
    shadow: 'none',
  },
});

type PreviewImageClassesProps = VariantProps<typeof previewImageClasses>;

withDefaults(
  defineProps<{
    // Variant classes
    frame?: PreviewImageClassesProps['frame'];
    shadow?: PreviewImageClassesProps['shadow'];

    // Image properties
    src: string;
    width?: number | string;
    height?: number | string;
    alt: string;
  }>(),
  {
    frame: 'none',
    shadow: 'none',
    width: '',
    height: '',
  }
);
</script>

<template>
  <img :src="src" :alt="alt" :width="width" :height="height" :class="previewImageClasses({ frame, shadow })" />
</template>
