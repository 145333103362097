<script setup lang="ts">
import type { RouteLocationRaw } from '#vue-router';
import { cva, type VariantProps } from 'class-variance-authority';

const buttonVariants = cva('btn', {
  variants: {
    size: {
      small: 'btn-small',
      medium: 'btn-medium',
      large: 'btn-large',
      xlarge: 'btn-xlarge',
      huge: 'btn-huge',
    },
    variant: {
      circle: 'btn-circle',
      ghost: 'btn-ghost',
      primary: 'btn-primary',
      text: 'btn-text',
    },
    fullWidth: {
      true: 'w-full',
    },
    disabled: {
      true: ['disabled', 'cursor-not-allowed'],
      false: 'cursor-pointer',
    },
  },
  defaultVariants: {
    size: 'medium',
    variant: 'primary',
  },
});

type ButtonVariantsProps = VariantProps<typeof buttonVariants>;

withDefaults(
  defineProps<{
    // Variant props
    size?: ButtonVariantsProps['size'];
    variant?: ButtonVariantsProps['variant'];
    fullWidth?: boolean;
    disabled?: boolean;

    // Props
    as?: string | Component;
    prependIcon?: Component | string | null;
    appendIcon?: Component | string | null;
    to?: RouteLocationRaw | null;
  }>(),
  {
    appendIcon: null,
    disabled: false,
    fullWidth: false,
    prependIcon: null,
    size: undefined,
    to: null,
    variant: undefined,
    as: 'button',
  }
);
</script>

<template>
  <component
    :is="as"
    v-bind="$props"
    :class="buttonVariants({ size, variant, fullWidth, disabled })"
    :disabled="disabled"
  >
    <template v-if="prependIcon">
      <component :is="prependIcon" class="btn__icon me-2 ms-0 stroke-2" />
    </template>

    <slot />

    <template v-if="appendIcon">
      <component :is="appendIcon" class="btn__icon me-0 ms-2 stroke-2" />
    </template>
  </component>
</template>
